import React from 'react';

import "./styles.scss";

function Footer() {
    return (
        <footer>
            2020 © Test Task App :)
        </footer>
    )
}

export default Footer